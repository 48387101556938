import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  Flex,
  useToast,
  Badge,
  Button,
  TableCaption,
  Select,
  Box,
  Text,
  GridItem
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCouponReport, getSites } from "../api";
import { exportToExcel } from "../utils/exportToExcel";
import { CouponReportTableHead } from "../components/TableHeads";
import CouponReportTable from "../containers/CouponReportTable";
import BarChart from "../components/BarChart";
import dayjs from "dayjs";
import Pagination from "../components/Pagination";
/**
 * Number of Bills vs Average transaction value report
 */
const CouponReport = () => {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const selectID = params.get("name");
  const defaultSiteID = params.get("id");

  const [emptyLoading, setemptyLoading] = useState(false);
  const [chartData1, setChartData1] = useState({
    xData: [],
    yData: [],
    optionScale: {}
  });
  const [chartData2, setChartData2] = useState({
    xData: [],
    yData: [],
    optionScale: {}
  });
  const [fromDate, setFromDate] = useState(
    new Date(dayjs().subtract(1, "months").format())
  );
  const [orderDetails, setOrderDetails] = useState([]);
  const [couponReportData, setCouponReportData] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [selectedSite, setSelectedSite] = useState(
    defaultSiteID ? defaultSiteID : "all"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowToShow, setRowToShow] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  // set chart data
  useEffect(() => {
    const xChartData = [];
    const y1ChartData = [];
    const y2ChartData = [];
    const y3ChartData = [];
    couponReportData?.map(item => {
      xChartData?.push(
        `${item?.coupon_code.toUpperCase()} (AED ${
          item?.coupon_discount_amount
        })`
      );
      y1ChartData?.push(item?.order_count);
      y2ChartData?.push(item?.total_discount_amount);
      y3ChartData?.push(item?.total_order_value);
    });
    setChartData1({
      xData: xChartData,
      yData: [
        {
          label: "Number of orders",
          data: y1ChartData,
          backgroundColor: ["rgb(75, 192, 192, 0.2)"],
          borderColor: ["rgb(75, 192, 192, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        }
      ],
      optionScale: {
        y: {
          position: "left",
          title: {
            display: true,
            text: "Number of orders",
            font: {
              size: 15
            }
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        },
        x: {
          position: "bottom"
        }
      }
    });
    setChartData2({
      xData: xChartData,
      yData: [
        {
          label: "Total discount amount",
          data: y2ChartData,
          backgroundColor: ["rgba(255, 159, 64, 0.2)"],
          borderColor: ["rgba(255, 159, 64, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        },
        {
          label: "Total order value",
          data: y3ChartData,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "y1"
        }
      ],
      optionScale: {
        y: {
          position: "left",
          title: {
            display: true,
            text: "Total discount amount",
            font: {
              size: 15
            }
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        },
        y1: {
          position: "right",
          title: {
            display: true,
            text: "Total order value",
            font: {
              size: 15
            }
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        },
        x: {
          position: "bottom"
        }
      }
    });
  }, [couponReportData]);
  const [sites, setSites] = useState([]);

  //load site data
  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReportData();
  }, [fromDate, toDate, selectedSite]);

  // get all data between two dates

  const handleToDate = date => {
    if (dayjs(date)?.diff(dayjs(fromDate), "days") < 0) {
      errorToast("error", "Error", "To date should be greater than from date");
      setFromDate(date);
    }
    if (dayjs(date)?.diff(dayjs(fromDate), "days") > 60) {
      errorToast(
        "info",
        "Info",
        "Maximum range that can be selected is 2 months"
      );
      setFromDate(new Date(dayjs(date).subtract(60, "days").format()));
    }
    setToDate(date);
  };

  const handleFromDate = date => {
    if (dayjs(toDate)?.diff(dayjs(date), "days") > 60) {
      setToDate(new Date(dayjs(date).add(60, "days").format()));
      errorToast(
        "info",
        "Info",
        "Maximum range that can be selected is 2 months"
      );
    }
    setFromDate(date);
  };
  const getReportData = async (
    store_id = selectedSite !== "all" ? parseInt(selectedSite) : "all",
    start_date = dayjs(fromDate)?.format("YYYY-MM-DD"),
    end_date = dayjs(toDate)?.format("YYYY-MM-DD")
  ) => {
    try {
      setemptyLoading(true);
      const response = await getCouponReport(store_id, start_date, end_date);
      setOrderDetails(response?.orders);
      setCouponReportData(response?.coupon_report);
    } catch (e) {
      errorToast("error", "Error", e?.data?.message);
    } finally {
      setemptyLoading(false);
    }
  };

  //pagination refresh
  useEffect(() => {
    setTotalPages(Math.ceil(couponReportData?.length / rowToShow) ?? 0);
    setCurrentPage(1);
  }, [rowToShow, couponReportData]);

  // customDatePicker 📅
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      disabled={emptyLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));

  const onDownloadClick = async () => {
    try {
      exportToExcel(
        "coupon_report",
        { orders: orderDetails, coupon: couponReportData },
        `Promotion Report-${dayjs(fromDate).format("DD-MM-YYYY")}-${dayjs(
          toDate
        ).format("DD-MM-YYYY")}:${selectedSite}`,
        "Promotion Report"
      );
    } catch (e) {
      console.log(e?.data?.message);
      errorToast("error", "Error", "To date should be greater than from date");
    } finally {
    }
  };

  //toast
  const errorToast = (type, title, msg) =>
    toast({
      title: title ?? "Something went wrong",
      description: msg ?? "Something went wrong",
      status: type,
      duration: 2500,
      isClosable: true
    });

  return (
    <Flex direction="column">
      <Box width="185px" mr="20px" my="10px">
        <Badge variant="subtle" fontSize="16px" colorScheme={"twitter"}>
          Promotion Report
        </Badge>
      </Box>
      <Flex direction="row" justifyContent="space-between">
        <Select
          variant="filled"
          // placeholder={sites?.length ? "All sites" : "No sites found"}
          value={selectedSite}
          onChange={e => {
            setSelectedSite(e.target.value);
          }}
          size="sm"
          width="150px"
          borderRadius="5px"
          mr="20px"
          my="10px"
        >
          <option value="all">All sites</option>
          {sites?.length
            ? sites?.map(site => {
                return (
                  <option
                    value={
                      selectID === "website_id"
                        ? site.sap_website_id
                        : site.default_store_id
                    }
                    key={
                      selectID === "website_id"
                        ? site.sap_website_id
                        : site.default_store_id
                    }
                  >
                    {site?.sap_website_id
                      ? `Site: ${site?.sap_website_id}`
                      : "All Site"}
                  </option>
                );
              })
            : null}
        </Select>
        <Flex my="10px" mr="20px">
          <Box mx={"20px"}>
            <DatePicker
              selected={fromDate}
              maxDate={new Date(toDate)}
              onChange={handleFromDate}
              dateFormat="PP"
              title={"Select From"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
          <Box mx={"20px"}>
            <DatePicker
              selected={toDate}
              maxDate={new Date()}
              onChange={handleToDate}
              dateFormat="PP"
              title={"Select To"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
        </Flex>
        <Button
          size="sm"
          isLoading={emptyLoading}
          disabled={emptyLoading || !selectedSite || !sites?.length}
          rightIcon={<DownloadIcon />}
          onClick={onDownloadClick}
          my="10px"
        >
          Download
        </Button>
      </Flex>
      <Flex my="10">
        <BarChart height="90px" chartData={chartData1} />
      </Flex>
      <Flex my="10">
        <BarChart height="90px" chartData={chartData2} />
      </Flex>
      <Flex>
        <Table variant="simple" size="md">
          <TableCaption placement={"top"} textAlign="left">
            Promotion Report
          </TableCaption>
          <CouponReportTableHead />
          <CouponReportTable
            data={couponReportData?.slice(
              (currentPage - 1) * rowToShow,
              currentPage * rowToShow
            )}
          />
        </Table>
      </Flex>
      <Pagination
        page={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        rowToShow={rowToShow}
        setRowToShow={setRowToShow}
      />
    </Flex>
  );
};
export default CouponReport;
