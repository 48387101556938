import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Flex, Box, Text } from "@chakra-ui/react";

import DownloadReport from "../components/DownloadReport";

import { getSites } from "../api";
/**
 * Landing page contain links to all report
 */
const Home = () => {
  const [sites, setSites] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
  }, []);
  /**
   * link to individual reports
   */
  const viewReport = (url, selectID, id) => {
    history.push(`${url}?name=${selectID}&id=${id}`);
  };
  // using both site_id and store_id
  const viewReport1 = (url, selectID, id) => {
    history.push(
      `${url}?name=${selectID}&site_id=${id.website_id}&store_id=${id.store_id}`
    );
  };
  return (
    <Flex
      height="calc(100vh - 120x)"
      justify="center"
      align="center"
      direction="column"
      paddingTop="5%"
    >
      <Box
        width="90%"
        borderWidth="1px"
        borderRadius="lg"
        padding="20px"
        overflow="hidden"
      >
        <Text textColor="red" fontSize="20px" fontWeight="bold">
          Report
        </Text>
        <Box width="100%" height="0%" borderWidth="1px" bg={"gray.400"}></Box>
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"SALES REPORT"}
          selectID={"website_id"}
          url={"/sales-report"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"END OF THE DAY REPORT"}
          selectID={"website_id"}
          url={"/eod-report"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"LOCATION REPORT"}
          selectID={"website_id"}
          url={"/location-report"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"CATOGORY PERFORMANCE REPORT"}
          selectID={"store_id"}
          url={"/category-performance"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"TOP/SLOW MOVING PRODUCTS REPORT"}
          selectID={"store_id"}
          url={"/top-slow-moving-products"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"ORDER STATUS REPORT"}
          selectID={"store_id"}
          url={"/orders-cancellation"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"NOB vs ATV REPORT"}
          selectID={"store_id"}
          url={"/nob-report"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"ORDER TIMELINE REPORT"}
          selectID={"website_id"}
          url={"/order-timeline-report"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"STOCK MOVEMENT REPORT"}
          selectID={"store_id"}
          url={"/stock-movement-report"}
        />
        <DownloadReport
          getSelectedID={viewReport}
          sites={sites}
          reportName={"PROMOTION REPORT"}
          selectID={"store_id"}
          url={"/promotion-report"}
        />
        <DownloadReport
          getSelectedID={viewReport1}
          sites={sites}
          reportName={"HUB LEVEL REPORT"}
          selectID={"store_id-site_id"}
          url={"/hub-level"}
        />
      </Box>
    </Flex>
  );
};

export default Home;
