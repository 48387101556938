import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableCaption,
  Flex,
  useToast,
  Select,
  Box,
  Badge,
  Button,
  Input,
} from "@chakra-ui/react";
import dayjs from "dayjs";

import { SalesTableHead } from "../components/TableHeads";
import ReportHeader from "../components/ReportHeader";
import SalesTable from "../containers/SalesTable";

import { exportToExcel } from "../utils/exportToExcel";

import { getSalesReport, getSites } from "../api";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/**
 * Sales report
 */
function SalesReport(props) {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const selectID = params.get("name");
  const defaultSiteID = params.get("id");

  const [emptyLoading, setemptyLoading] = useState(false);
  const [moreLoading, setmoreLoading] = useState(false);
  const [downloadLoading, setdownloadLoading] = useState(false);
  const [canLoadMore, setcanLoadMore] = useState(true);
  const [report, setreport] = useState([]);
  const [filterReport, setfilterReport] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(defaultSiteID);
  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    limit: "10",
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSales = async () => {
    setemptyLoading(true);
    try{
      const response = await getSalesReport(selectedSite, {
        ...filters,
        page: 1,
      });
      setreport(response);
      setfilterReport(response);
      setCurrentPage(1);
      setcanLoadMore(true);
      setemptyLoading(false);
    } catch(err){
      console.log(err)
      setemptyLoading(false);
      setcanLoadMore(false);
      setCurrentPage(1);
      setreport([]);
      setfilterReport([]);
    }
  };

  const getMoreSales = async () => {
    setmoreLoading(true);
    try{
      const response = await getSalesReport(selectedSite, {
        ...filters,
        page: currentPage + 1,
      });
      const totalRow = response[response.length - 1];
      let newRow = report[report.length - 1];
      const fieldsToAdd = [
        "Order Value",
        "Tax Amount",
        "Shipping Fee",
        "Discount",
        "Order Value with Tax",
        "Returned Value with Tax",
      ];
      fieldsToAdd.forEach((f) => {
        newRow[f] =
          Math.round((newRow[f] + totalRow[f] + Number.EPSILON) * 100) / 100;
      });
      setmoreLoading(false);
      if (response.length > 1) {
        setreport([
          ...report.filter((r) => r["SO Invoice Number"] !== "Total:"),
          ...response.filter((r) => r["SO Invoice Number"] !== "Total:"),
          newRow,
        ]);
        setfilterReport([
          ...report.filter((r) => r["SO Invoice Number"] !== "Total:"),
          ...response.filter((r) => r["SO Invoice Number"] !== "Total:"),
          newRow,
        ]);
        setCurrentPage(currentPage + 1);
      }
      if (response.length === 1) {
        setcanLoadMore(false);
      }
    } catch(err){
      console.log(err)
      setmoreLoading(false);
    }
    
  };

  useEffect(() => {
    getSales();
  }, [filters, selectedSite]);

  const onDownloadClick = async () => {
    setdownloadLoading(true);
    try {
      const response = await getSalesReport(selectedSite, {
        ...filters,
        page: 0,
      });
      const { startDate, endDate } = filters;
      exportToExcel(
        "sales",
        response,
        `SALES-${startDate}${
          endDate === startDate ? "" : `-${endDate}`
        }-${selectedSite}`,
        "sales"
      );
    } catch (e) {
      console.log(e);
      errorToast(e);
    } finally {
      setdownloadLoading(false);
    }
  };

  const searchFilter = (value) => {
    setfilterReport(
      report.filter((item) => {
        return item["Order ID"]
          .toLowerCase()
          .includes(value.toLowerCase().trim());
      })
    );
  };

  const errorToast = (error) =>
    toast({
      title: "Failed to generate report",
      description: error?.data?.message ?? "Something went wrong",
      status: "error",
      duration: 2500,
      isClosable: true,
    });

  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      disabled={emptyLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));

  return (
    <Flex direction="column">
      <Flex direction="column">
        {/* <Center> */}
        <Box width="185px" mr="20px" my="10px">
          <Badge variant="subtle" fontSize="16px" colorScheme="twitter">
            Sales Report
          </Badge>
        </Box>
        {/* </Center> */}
        <Flex
          width={"100%"}
          wrap="wrap"
          justifyContent="space-between"
          my="10px"
        >
          <Select
            variant="filled"
            placeholder={sites?.length ? "Select site" : "No sites found"}
            value={selectedSite}
            onChange={(e) => {
              setSelectedSite(e.target.value);
            }}
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            {sites?.length
              ? sites?.map((site) => (
                  <option
                    value={
                      selectID === "website_id"
                        ? site.sap_website_id
                        : site.default_store_id
                    }
                    key={
                      selectID === "website_id"
                        ? site.sap_website_id
                        : site.default_store_id
                    }
                  >
                    Site:&nbsp;{site?.sap_website_id}
                  </option>
                ))
              : null}
          </Select>
          <Box my="10px" mr="20px">
            <DatePicker
              selected={filters.startDate}
              maxDate={new Date()}
              onChange={(date) => {
                setFilters((f) => {
                  return { ...f, startDate: date };
                });
              }}
              dateFormat="PP"
              title={"Select Start Date"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
          <Box my="10px" mr="20px">
            <DatePicker
              selected={filters.endDate}
              maxDate={new Date()}
              onChange={(date) => {
                setFilters((f) => {
                  return { ...f, endDate: date };
                });
              }}
              dateFormat="PP"
              title={"Select End Date"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
          <Input
            placeholder="search"
            size="sm"
            my="10px"
            mr="20px"
            width="350px"
            onChange={(e) => searchFilter(e.target.value)}
          />
        </Flex>

        <Flex
          width={"100%"}
          wrap="wrap"
          justifyContent="space-between"
          my="10px"
        >
          <Select
            variant="filled"
            value={filters.limit}
            onChange={(e) => {
              setFilters((f) => {
                return { ...f, limit: e.target.value };
              });
            }}
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            <option value="10">10 rows</option>
            <option value="50">50 rows</option>
            <option value="100">100 rows</option>
          </Select>
          <Button
            size="sm"
            isLoading={downloadLoading}
            disabled={
              emptyLoading || downloadLoading || !selectedSite || !sites?.length
            }
            rightIcon={<DownloadIcon />}
            onClick={onDownloadClick}
            my="10px"
          >
            Download
          </Button>
        </Flex>
      </Flex>
      <Flex overflowX="auto">
        <Table variant="simple" size="sm">
          <TableCaption placement={"top"}>
            {/* Sales Report */}
            {/* {showTableCaption ? tableCaption : ""} */}
          </TableCaption>
          <SalesTableHead />
          <SalesTable report={filterReport ?? []} emptyLoading={emptyLoading} />
        </Table>
      </Flex>
      <Flex justifyContent="center">
        <Button
          size="sm"
          isLoading={moreLoading}
          disabled={moreLoading || emptyLoading || !canLoadMore}
          my="20px"
          onClick={() => getMoreSales()}
        >
          Load More
        </Button>
      </Flex>
    </Flex>
  );
}
export default SalesReport;
