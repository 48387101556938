const reportList = [
  {
    reportName: "SALES",
    selectID: "website_id",
    url: "/sales-report",
    default_id: 8042
  },
  {
    reportName: "END OF THE DAY",
    selectID: "website_id",
    url: "/eod-report",
    default_id: 8042
  },
  {
    reportName: "LOCATION",
    selectID: "website_id",
    url: "/location-report",
    default_id: 8042
  },
  {
    reportName: "CATEGORY PERFORMANCE",
    selectID: "store_id",
    url: "/category-performance",
    default_id: 1
  },
  {
    reportName: "TOP/SLOW MOVING PRODUCTS",
    selectID: "store_id",
    url: "/top-slow-moving-products",
    default_id: 1
  },
  {
    reportName: "ORDER STATUS",
    selectID: "store_id",
    url: "/orders-cancellation",
    default_id: 1
  },
  {
    reportName: "NOB vs ATV",
    selectID: "store_id",
    url: "/nob-report",
    default_id: 1
  },
  {
    reportName: "ORDER TIMELINE",
    selectID: "website_id",
    url: "/order-timeline-report",
    default_id: 8042
  },
  {
    reportName: "STOCK MOVEMENT",
    selectID: "store_id",
    url: "/stock-movement-report",
    default_id: 1
  },
  {
    reportName: "PROMOTION REPORT",
    selectID: "store_id",
    url: "/coupon-report",
    default_id: 1
  },
  {
    reportName: "HUB LEVEL",
    selectID: "store_id-site_id",
    url: "/hub-level",
    default_web_id: { store: 1, site: 8042 }
  }
];
export default reportList;
