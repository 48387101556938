import XLSX from "xlsx";

/**
 * To generate an Excel sheet
 * @param {string} type
 * @param {*} excelData
 * @param {string} fileName
 * @param {string} sheetName
 */

export const exportToExcel = (type, excelData, fileName, sheetName) => {
  try {
    const fileExtension = ".xlsx";

    /**
     * Tried code reuse by creating single xlsx instance & for write to file
     * but the report became empty for some reason
     */
    switch (type) {
      case "sales": {
        const workbook = XLSX.utils.book_new();
        const salesSheet = XLSX.utils.json_to_sheet(excelData);
        XLSX.utils.book_append_sheet(workbook, salesSheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "agent_v1": {
        const workbook = XLSX.utils.book_new();

        excelData?.agents?.forEach(agent => {
          const orders = agent?.orders;
          const deliveryAgent = agent?.agentName?.trim() || "Agent";
          const agentOrdersSheet = XLSX.utils.json_to_sheet(orders);
          XLSX.utils.book_append_sheet(
            workbook,
            agentOrdersSheet,
            deliveryAgent
          );
        });

        Object.keys(excelData?.reports).forEach(day => {
          const reportsFormatted = excelData?.reports[day]?.map(report => {
            if (report.type === "sum") {
              report.report.agent_name = "Total";
              report.report.vehicle = day;
              return report.report;
            }
            return report;
          });

          const reportSheet = XLSX.utils.json_to_sheet(reportsFormatted);
          XLSX.utils.book_append_sheet(workbook, reportSheet, `Report-${day}`);
        });

        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "agent": {
        let agent_summary = [];
        const workbook = XLSX.utils.book_new();

        excelData.forEach((item, index) => {
          if (index !== excelData.length - 1) {
            const ordersSheet = XLSX.utils.json_to_sheet([
              ...item.orders
              // ...item.ordersInTransit,
            ]);
            XLSX.utils.book_append_sheet(workbook, ordersSheet, item.agentName);
          }
        });

        excelData.forEach(item => {
          if (item.type === "sum") {
            item.report["agent_name"] = "Total";
          } else {
            item.report["agent_name"] = item.agentName;
            item.report["agentId"] = item.agentId;
            item.report["date"] = item.date;
          }
          agent_summary.push(item.report);
        });
        const reportSheet = XLSX.utils.json_to_sheet(agent_summary, {
          header: ["agent_name"]
        });
        XLSX.utils.book_append_sheet(
          workbook,
          reportSheet,
          `Report-${excelData[0].date}`
        );
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "summary": {
        let summary = [];
        const workbook = XLSX.utils.book_new();

        Object.keys(excelData).forEach(key => {
          excelData[key][""] = key;
          summary.push(excelData[key]);
        });
        const summarySheet = XLSX.utils.json_to_sheet(summary, {
          header: [
            "",
            "cash_sales_count",
            "cash_sales_total",
            "card_sales_count",
            "card_sales_total",
            "online_sales_count",
            "online_sales_total",
            "total_order_count",
            "total_sales"
          ]
        });
        XLSX.utils.book_append_sheet(workbook, summarySheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "location": {
        const workbook = XLSX.utils.book_new();
        const locationSheet = XLSX.utils.json_to_sheet(excelData);
        locationSheet["!cols"] = [
          { width: 20 }, // first column
          { width: 20 }, // second column
          { width: 20 }, //...
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 }
        ];
        XLSX.utils.book_append_sheet(workbook, locationSheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "tender": {
        const workbook = XLSX.utils.book_new();
        const tenderSalesData = XLSX.utils.json_to_sheet(
          excelData.tenderSalesData
        );
        XLSX.utils.book_append_sheet(
          workbook,
          tenderSalesData,
          "Tender Sales Data"
        );
        const tenderSalesReport = XLSX.utils.json_to_sheet(
          excelData.tenderSalesReport
        );
        XLSX.utils.book_append_sheet(
          workbook,
          tenderSalesReport,
          "Tender Sales Report"
        );
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "reconciliation": {
        let salesHeader = [],
          tenderHeader = [];
        const workbook = XLSX.utils.book_new();

        Object.keys(excelData.lhs).forEach(key => {
          salesHeader.push(key);
        });
        Object.keys(excelData.rhs).forEach(key => {
          tenderHeader.push(key);
        });
        const reconciliationSalesSheet = XLSX.utils.json_to_sheet(
          [excelData.lhs],
          {
            header: salesHeader
          }
        );
        XLSX.utils.book_append_sheet(
          workbook,
          reconciliationSalesSheet,
          "Sales"
        );
        const reconciliationTenderSheet = XLSX.utils.json_to_sheet(
          [excelData.rhs],
          {
            header: tenderHeader
          }
        );
        XLSX.utils.book_append_sheet(
          workbook,
          reconciliationTenderSheet,
          "Tender or Collections"
        );
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "reconciliation_sample": {
        let reconciliationData = [
            {
              sales: "E - Com Sales",
              sales_amount: excelData?.lhs?.totalSales,
              tender: "E - Com Cash",
              tender_amount: excelData?.rhs?.tenderCashTotal
            },
            {
              sales: "E - Com Sales Return",
              sales_amount: excelData?.lhs?.totalReturnSales,
              tender: "E - Com Payment Gateway",
              tender_amount: excelData.rhs?.tenderPaymentGatewayTotal
            },
            {
              sales: "E - Com Trade Discount",
              sales_amount: excelData?.lhs?.tradeDiscount,
              tender: "E - Com Card On Delivery",
              tender_amount: excelData?.rhs?.tenderCardTotal
            },
            {
              sales: "E - Com Promo Discount",
              sales_amount: excelData?.lhs?.promoDiscount,
              tender: "E - Com Credit Card Visa",
              tender_amount: excelData?.rhs?.tenderVisaCardTotal
            },
            {
              sales: "",
              sales_amount: "",
              tender: "E - Com Credit Card Master",
              tender_amount: excelData?.rhs?.tenderMasterCardTotal
            },
            {
              sales: "",
              sales_amount: "",
              tender: "E - Com Credit Card Amex",
              tender_amount: excelData?.rhs?.tenderAmexCardTotal
            },
            {
              sales: "",
              sales_amount: "",
              tender: "E - Com Others",
              tender_amount: excelData?.rhs?.tenderOtherCardTotal
            },
            {
              sales: "Delivery Charges",
              sales_amount: excelData?.lhs?.deliveryCharges,
              tender: "E - Com Gift Card",
              tender_amount: excelData?.rhs?.giftCard
            },
            {
              sales: "Cash Received(Excess)",
              sales_amount: excelData?.lhs?.excessCash,
              tender: "E - Com Credit Wallet",
              tender_amount: excelData?.rhs?.creditWallet
            },
            {
              sales: "Net E-Com Sales",
              sales_amount: excelData?.lhs?.netSales,
              tender: "E - Com Payit E Wallet",
              tender_amount: excelData?.rhs?.payitEWallet
            },
            {
              sales: "",
              sales_amount: "",
              tender: "Benefit Payment",
              tender_amount: excelData?.rhs?.benefitPayment
            },
            {
              sales: "Output Tax Value",
              sales_amount: excelData?.lhs?.outputTaxValue,
              tender: "Loyalty Gift Voucher",
              tender_amount: excelData?.rhs?.loyaltyGiftVoucher
            },
            {
              sales: "Difference Tax Value",
              sales_amount: excelData?.lhs?.differenceTaxValue,
              tender: "Credit Note",
              tender_amount: excelData?.rhs?.creditNote
            },
            {
              sales: "",
              sales_amount: "",
              tender: "Offer Voucher",
              tender_amount: excelData?.rhs?.offerVoucher
            },
            {
              sales: "",
              sales_amount: "",
              tender: "Vendor Discount Voucher",
              tender_amount: excelData?.rhs?.venderDiscountVoucher
            },
            {
              sales: "",
              sales_amount: "",
              tender: "Vendor Voucher-FMCG",
              tender_amount: excelData?.rhs?.vendorVoucherFMCG
            },
            {
              sales: "",
              sales_amount: "",
              tender: "Cash Refund(Shortage)",
              tender_amount: excelData?.rhs?.shortageCash
            },
            {
              sales: "Grand Total",
              sales_amount: excelData?.lhs?.grandTotal,
              tender: "",
              tender_amount: excelData?.rhs?.tenderTotal
            },
            {
              sales: "",
              sales_amount: "",
              tender: "",
              tender_amount: ""
            },
            {
              sales: excelData?.report_closed ? "Document number" : "",
              sales_amount: excelData?.report_closed
                ? excelData?.document_number
                : ""
            },
            {
              sales: "Report Status",
              sales_amount: excelData?.report_closed ? "Closed" : "Not Closed"
            },
            {
              sales: "Site",
              sales_amount: excelData.site
            },
            {
              sales: "Date",
              sales_amount: excelData.date
            }
          ],
          heading = [["Sales", "Amount", "Tender", "Amount"]];
        // lhs = Object.keys(excelData.lhs),
        // rhs = Object.keys(excelData.rhs);
        const workbook = XLSX.utils.book_new();

        // Object.keys(excelData.lhs).forEach((key) => {
        //   salesHeader.push(key);
        // });

        // modify this for empty lhs
        // Object.keys(excelData.rhs).forEach((key, index) => {
        //   if (key === "tenderOtherCardTotal") {
        //     reconciliationData.push({
        //       sales: "",
        //       sales_amount: "",
        //       tender: key,
        //       tender_amount: excelData.rhs[key],
        //     });
        //     index = index - 1;
        //   } else {
        //     reconciliationData.push({
        //       sales: lhs[index],
        //       sales_amount: excelData.lhs[lhs[index]],
        //       tender: key,
        //       tender_amount: excelData.rhs[key],
        //     });
        //   }
        // });

        // for (let index = 0; index < excelData.rhs.length; index++) {
        //   let object={lhs[index]:rhs[index]}
        //   reconciliationData.push(lhs[index]);
        // }
        XLSX.utils.sheet_add_aoa(workbook, heading);
        const reconciliationSheet = XLSX.utils.json_to_sheet(
          reconciliationData
          // { origin: "A2", skipHeader: true }
        );
        reconciliationSheet["!cols"] = [
          { width: 20 }, // first column
          { width: 20 }, // second column
          { width: 20 }, //...
          { width: 20 }
        ];
        console.log(reconciliationSheet);
        reconciliationSheet.A1.v = "Sales";
        reconciliationSheet.B1.v = "Amount";
        reconciliationSheet.C1.v = "Tender";
        reconciliationSheet.D1.v = "Amount";
        XLSX.utils.book_append_sheet(
          workbook,
          reconciliationSheet,
          "Reconciliation Summary"
        );

        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "category_report": {
        const workbook = XLSX.utils.book_new();

        const categorySheet = XLSX.utils.json_to_sheet(excelData, {
          header: ["category_id", "name", "total_qty", "total_sales"]
        });
        categorySheet["!cols"] = [];
        categorySheet["!cols"][4] = { hidden: true };
        categorySheet["!cols"][5] = { hidden: true };
        categorySheet["!cols"][6] = { hidden: true };
        XLSX.utils.book_append_sheet(workbook, categorySheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "top_slow_moving_report": {
        console.log(excelData);
        const workbook = XLSX.utils.book_new();
        const topMovingProductsSheet = XLSX.utils.json_to_sheet(
          excelData?.top_moving_products
        );
        XLSX.utils.book_append_sheet(
          workbook,
          topMovingProductsSheet,
          "Top Moving Products"
        );

        const slowMovingProductsSheet = XLSX.utils.json_to_sheet(
          excelData?.slow_moving_products
        );
        XLSX.utils.book_append_sheet(
          workbook,
          slowMovingProductsSheet,
          "Slow Moving Products"
        );
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "order_status": {
        // let orders = [];
        const workbook = XLSX.utils.book_new();

        // Object.keys(excelData?.orderData).forEach((key) => {
        //   orders.push(excelData?.orderData[key][0]);
        // });
        const orderStatusSheet = XLSX.utils.json_to_sheet(excelData);
        XLSX.utils.book_append_sheet(workbook, orderStatusSheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "ordertimeline": {
        let ordertimeline = [];
        const workbook = XLSX.utils.book_new();

        excelData.map(item => {
          ordertimeline.push({
            Timeslot: item?.timeslot,
            "Express Orders": item?.express_orders,
            "Online Payment Orders": item?.online_payment_orders,
            "Pay On Delivery Orders": item?.pay_on_delivery_orders,
            "Scheduled Orders": item?.scheduled_orders,
            "Total Orders": item?.total_orders
          });
        });
        const ordertimelineSheet = XLSX.utils.json_to_sheet(ordertimeline);
        XLSX.utils.book_append_sheet(workbook, ordertimelineSheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "nobatvreport": {
        let nobatv = [];
        const workbook = XLSX.utils.book_new();

        Object.values(excelData).forEach(value => {
          nobatv.push({
            Date: Object.keys(value)[0],
            "Number of Bills": Object.values(value)[0]?.nob,
            "Average Transaction Value": Object.values(value)[0]?.atv
          });
        });
        const nobatvSheet = XLSX.utils.json_to_sheet(nobatv);
        XLSX.utils.book_append_sheet(workbook, nobatvSheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "stockmovementreport": {
        let stockmovement = [];
        const workbook = XLSX.utils.book_new();

        excelData.map(item => {
          stockmovement.push({
            Id: item?.product_id,
            Name: item?.name,
            "Ouantity Sold": item?.sold_qty,
            Stock: item?.stock,
            "Reorder Limit": item?.rol
          });
        });
        const stockmovementSheet = XLSX.utils.json_to_sheet(stockmovement);
        XLSX.utils.book_append_sheet(workbook, stockmovementSheet, sheetName);
        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "hub_level": {
        console.log(excelData);
        const workbook = XLSX.utils.book_new();
        // date_wise_placed_orders
        const date_wise_placed_orders_Sheet = XLSX.utils.json_to_sheet(
          excelData?.date_wise_placed_orders
        );
        XLSX.utils.book_append_sheet(
          workbook,
          date_wise_placed_orders_Sheet,
          "Placed orders"
        );

        // date_wise_returns
        const date_wise_returns_sheet = XLSX.utils.json_to_sheet(
          excelData?.date_wise_returns
        );
        XLSX.utils.book_append_sheet(
          workbook,
          date_wise_returns_sheet,
          "Returns"
        );
        // date_wise_date_wise_cancellations
        const date_wise_date_wise_cancellations_sheet =
          XLSX.utils.json_to_sheet(
            excelData?.date_wise_date_wise_cancellations
          );
        XLSX.utils.book_append_sheet(
          workbook,
          date_wise_date_wise_cancellations_sheet,
          "Cancellations"
        );
        // date_wise_sales
        const date_wise_sales_sheet = XLSX.utils.json_to_sheet(
          excelData?.date_wise_sales
        );
        XLSX.utils.book_append_sheet(
          workbook,
          date_wise_sales_sheet,
          "Date wise Sales"
        );
        // timeslot_wise_sales
        const timeslot_wise_sales_sheet = XLSX.utils.json_to_sheet(
          excelData?.timeslot_wise_sales
        );
        XLSX.utils.book_append_sheet(
          workbook,
          timeslot_wise_sales_sheet,
          "Timeslot wise Sales"
        );
        // hub level
        let hub_level_array = [
          {
            start_date: excelData?.start_date,
            end_date: excelData?.end_date,
            hub_atv: excelData?.hub_atv,
            store: excelData?.store,
            total_express_orders: excelData?.total_express_orders,
            total_express_sales: excelData?.total_express_sales,
            total_invoiced_orders: excelData?.total_invoiced_orders,
            total_pay_on_delivery_orders:
              excelData?.total_pay_on_delivery_orders,
            total_pay_on_delivery_sales: excelData?.total_pay_on_delivery_sales,
            total_payment_gateway_orders:
              excelData?.total_payment_gateway_orders,
            total_payment_gateway_sales: excelData?.total_payment_gateway_sales,
            total_placed_orders: excelData?.total_placed_orders,
            total_sales: excelData?.total_sales,
            total_scheduled_orders: excelData?.total_scheduled_orders,
            total_scheduled_sales: excelData?.total_scheduled_sales
          }
        ];

        const hub_level_sheet = XLSX.utils.json_to_sheet(hub_level_array);
        XLSX.utils.book_append_sheet(workbook, hub_level_sheet, "Hub level");

        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
      case "coupon_report": {
        let couponReport = [];
        const workbook = XLSX.utils.book_new();

        excelData?.coupon?.map(item => {
          couponReport.push({
            "Coupon Code": item?.coupon_code.toUpperCase(),
            "Discount Amount": item?.coupon_discount_amount,
            "Number of Orders": item?.order_count,
            "Total Discount Amount": item?.total_discount_amount,
            "Total Order Value": item?.total_order_value
          });
        });
        const couponReportSheet = XLSX.utils.json_to_sheet(couponReport);
        XLSX.utils.book_append_sheet(workbook, couponReportSheet, sheetName);

        excelData?.coupon?.map(item => {
          let sheetData = excelData?.orders?.filter(
            orderItem =>
              orderItem?.coupon_code.toUpperCase() ===
              item?.coupon_code.toUpperCase()
          );
          let orderReport = [];
          sheetData?.map(couponItem => {
            orderReport.push({
              "Order Id": couponItem?.order_id,
              "Coupon Discount Amount": couponItem?.coupon_discount_amount,
              "Customer Name": couponItem?.customer_name,
              "Customer Id": couponItem?.customer_id,
              Address: couponItem?.address,
              City: couponItem?.city,
              Country: couponItem?.country,
              "Order Value": couponItem?.order_value,
              "Order Discount Amount": couponItem?.order_discount_amount,
              "Purchase Date": couponItem?.purchase_date,
              "Order Status": couponItem?.status,
              "Coupon Code": couponItem?.coupon_code.toUpperCase(),
              "Store ID": couponItem?.store_id,
              "Entity ID": couponItem?.entity_id
            });
          });
          const orderReportSheet = XLSX.utils.json_to_sheet(orderReport);
          XLSX.utils.book_append_sheet(
            workbook,
            orderReportSheet,
            item?.coupon_code.toUpperCase()
          );
        });

        XLSX.writeFile(workbook, fileName + fileExtension);
        break;
      }
    }
  } catch (e) {
    throw e;
  }
};
